import {
  Box,
  Button,
  Drawer,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import { Close, Menu } from "@material-ui/icons";
import clsx from "clsx";
import { TFunction } from "next-i18next";
import React, { useState } from "react";
import { Router } from "../../../i18n";
import UserProfileIcon from "../../../public/img/UserProfileIcon";
import useStyles from "../../../styles/components/Navbar";

type MenuNavbarProps = {
  readonly t: TFunction;
  onClickAuth?: () => void;
  onClickOrderRoom?: () => void;
  isLogin: boolean;
};

const MenuNavbar = (props: MenuNavbarProps) => {
  const { t, onClickAuth, onClickOrderRoom, isLogin } = props;
  const classes = useStyles();
  const tabScreen = useMediaQuery("(max-width:768px)");
  const [openNavbar, setOpenNavbar] = useState(false);
  const tenantModule = process.env.TENANT_MODULE?.split(",");
  const tenantWhatsapp = process.env.TENANT_WHATSAPP;

  const onClickMenu = (type) => {
    if (type === "room") {
      if (onClickOrderRoom) {
        onClickOrderRoom();
      }
      setOpenNavbar(false);
      return;
    }
    if (type === "food") {
      Router.push("/hotels/foods/search-location");
    }
    if (type === "souvenir") {
      Router.push("/hotels/souvenirs/search-location");
    }
    if (type === "wallet") {
      if (onClickAuth && !isLogin) {
        onClickAuth();
      } else {
        Router.push("/balance?page=wallet");
      }
    }
  };

  return (
    <Box
      className={clsx(
        classes.navbarWrapper,
        process.env.DARK_COLOR_NAVBAR === "true" && classes.navbarWrapperDark
      )}
    >
      <Box className={clsx(classes.navbarContent, classes.navbarMenuWrapper)}>
        <img
          src={
            process.env.NEXT_PUBLIC_COLOR_LOGO ||
            "https://swaps-bucket.s3.ap-southeast-1.amazonaws.com/Group%2022.png-2022-08-30T06%3A18%3A48.910Z"
          }
          alt="logo"
          width={process.env.WIDTH_LOGO || 50}
          onClick={() => Router.push("/")}
          className={classes.icon}
        />
        {!tabScreen ? (
          <ul className={classes.menuWrapper}>
            {tenantModule?.map((type, index) => (
              <li
                className={clsx(
                  classes.menu,
                  process.env.DARK_COLOR_NAVBAR === "true" && classes.menuDark
                )}
                key={index}
                onClick={() => onClickMenu(type)}
              >
                {t(type)}
              </li>
            ))}
            <li
              className={clsx(
                classes.menu,
                process.env.DARK_COLOR_NAVBAR === "true" && classes.menuDark
              )}
              onClick={() => {
                window.open(
                  `https://wa.me/${tenantWhatsapp || "6288802870057"}`,
                  "_blank"
                );
              }}
            >
              {t("contact")}
            </li>
            {onClickAuth && !isLogin && (
              <li
                className={clsx(
                  classes.menu,
                  process.env.DARK_COLOR_NAVBAR === "true" && classes.menuDark
                )}
              >
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  className={classes.loginButton}
                  onClick={onClickAuth}
                >
                  {t("login")}
                </Button>
              </li>
            )}
            {isLogin && (
              <IconButton className={classes.iconButton} onClick={onClickAuth}>
                <UserProfileIcon />
              </IconButton>
            )}
          </ul>
        ) : (
          <Box className={classes.mobileIconWrapper}>
            {isLogin && (
              <IconButton className={classes.iconButton} onClick={onClickAuth}>
                <UserProfileIcon />
              </IconButton>
            )}
            <Menu
              className={classes.icon}
              onClick={() => setOpenNavbar(true)}
            />
          </Box>
        )}
        <Drawer
          anchor="top"
          open={openNavbar}
          onClose={() => setOpenNavbar(false)}
        >
          <Box p="16px">
            <Box width="100%" display="flex" justifyContent="flex-end">
              <Close
                className={classes.icon}
                onClick={() => setOpenNavbar(false)}
              />
            </Box>
            <ul className={classes.menuWrapper}>
              {tenantModule?.map((type, index) => (
                <li
                  className={clsx(
                    classes.menu,
                    process.env.DARK_COLOR_NAVBAR === "true" && classes.menuDark
                  )}
                  key={index}
                  onClick={() => onClickMenu(type)}
                >
                  {t(type)}
                </li>
              ))}
              <li
                className={clsx(
                  classes.menu,
                  process.env.DARK_COLOR_NAVBAR === "true" && classes.menuDark
                )}
                onClick={() => {
                  window.open(
                    `https://wa.me/${tenantWhatsapp || "6288802870057"}`,
                    "_blank"
                  );
                }}
              >
                {t("contact")}
              </li>
              {onClickAuth && !isLogin && (
                <li
                  className={clsx(
                    classes.menu,
                    process.env.DARK_COLOR_NAVBAR === "true" && classes.menuDark
                  )}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    className={classes.loginButton}
                    onClick={onClickAuth}
                  >
                    {t("login")}
                  </Button>
                </li>
              )}
            </ul>
          </Box>
        </Drawer>
      </Box>
    </Box>
  );
};

export default MenuNavbar;
